﻿// Colours
$color-light-green: #65a12f;
$color-dark-green: #3f740f;
$color-black-green: #1d2813;

$color-light-parchment: #fffeee;
$color-normal-parchment: #f8f2e3;
$color-dark-parchment: #f3e8cc;

$color-light-orange: #ff6a01;
$color-dark-orange: #ea4f00;

$color-light-yellow: #f6f2bd;
$color-normal-yellow: #f6ee80;
$color-dark-yellow: #d3c93e;

$color-light-red: #f2cdc5;
$color-normal-red: #d0021b;
$color-dark-red: #a9182f;

$color-light-blue: #2f68a1;
$color-dark-blue: #0f4173;

$color-black: #111111;
$color-grey: #444c3c;
$color-light-grey: #cccccc;
$color-white: #ffffff;

$color-admin-black: #262626;
$color-admin-text-grey: #737373;
$color-admin-border-grey: #E5E5E5;
$color-admin-background-grey: #FBF8F6;
$color-admin-surface-grey: #FAFAFA;
$color-admin-rich-green: #3F740F;
$color-admin-surface-green: #FAFFF2;
$color-admin-light-blue: #E0F2FE;
$color-admin-dark-blue: #5398AB;
$color-admin-light-yellow: #FFF9E9;
$color-admin-dark-yellow: #7f692b;

$color-public-background-grey: #F5F5F4;
