@use '_colors.scss';

/* VARIABLES
-------------------------------------------------- */

// Fonts
$font-main: "Karla", sans-serif;
$font-header: "Goudy Heavyface", "Georgia", serif;

// Device Sizes
// Smallest size: < 380px
$device-size-tiny: 380px;
$device-size-smallest: 460px;
$device-size-small: 600px;
$device-size-medium: 960px;
$device-size-large: 1280px;

// Margins
$margin-small: 1.2rem;
$margin-medium: 2.0rem;
$margin-xlarge: 8.0rem;

// Other Measurements
$corner-rounding: 0.6rem;


/* COMPATIBILITY MIXINS
-------------------------------------------------- */

@mixin displayflex() {
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
}

@mixin flex-direction($direction) {
    flex-direction: $direction;
    -ms-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -webkit-flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
    flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -webkit-flex-wrap: $wrap;
}

@mixin flex-flow($flow) {
    flex-flow: $flow;
    -ms-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -webkit-flex-flow: $flow;
}

@mixin justify-content($justify) {
    justify-content: $justify;
    -ms-justify-content: $justify;
    -moz-justify-content: $justify;
    -webkit-justify-content: $justify;
}

@mixin align-items($align) {
    align-items: $align;
    -ms-align-items: $align;
    -moz-align-items: $align;
    -webkit-align-items: $align;
}

@mixin align-content($align) {
    align-content: $align;
    -ms-align-content: $align;
    -moz-align-content: $align;
    -webkit-align-content: $align;
}

@mixin order($order) {
    order: $order;
    -ms-order: $order;
    -moz-order: $order;
    -webkit-order: $order;
}

@mixin flex-grow($grow) {
    flex-grow: $grow;
    -ms-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -webkit-flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
    flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -webkit-flex-shrink: $shrink;
}

@mixin flex-basis($basis) {
    flex-basis: $basis;
    -ms-flex-basis: $basis;
    -moz-flex-basis: $basis;
    -webkit-flex-basis: $basis;
}

@mixin flex($value) {
    flex: $value;
    -ms-flex: $value;
    -moz-flex: $value;
    -webkit-flex: $value;
}

@mixin align-self($align) {
    align-self: $align;
    -ms-align-self: $align;
    -moz-align-self: $align;
    -webkit-align-self: $align;
}

@mixin transform($transform) {
    transform: $transform;
    -ms-transform: $transform;
    -moz-transform: $transform;
    -webkit-transform: $transform;
}

@mixin appearance($appearance) {
    appearance: $appearance;
    -moz-appearance: $appearance;
    -webkit-appearance: $appearance;
}


/* MIXINS
-------------------------------------------------- */

@mixin respond-to($min-width) {
    @media only screen and (min-width: $min-width) {
        @content;
    }
}

@mixin backgroundimage($imagename) {
    background-image: url(/Content/images/backgrounds/#{$imagename}_sm.jpg);

    @include respond-to($device-size-large)
    {
        background-image: url(/Content/images/backgrounds/#{$imagename}.jpg);
    }
}

@mixin darkenimage($imagename, $alpha: 0.45) {
    background-image: linear-gradient(rgba(50, 50, 50, $alpha), rgba(50, 50, 50, $alpha)), url('/Content/images/backgrounds/#{$imagename}_sm.jpg');

    @include respond-to($device-size-large)
    {
        background-image: linear-gradient(rgba(50, 50, 50, $alpha), rgba(50, 50, 50, $alpha)), url('/Content/images/backgrounds/#{$imagename}.jpg');
    }
}


/* ATOMS
-------------------------------------------------- */

@font-face {
    font-family: 'Goudy Heavyface';
    src: url('/Content/fonts/GoudyHeavyface.eot');
    src: url('/Content/fonts/GoudyHeavyface.eot?#iefix') format('embedded-opentype'),
    url('/Content/fonts/GoudyHeavyface.woff') format('woff'),
    url('/Content/fonts/GoudyHeavyface.ttf') format('truetype'),
    url('/Content/fonts/GoudyHeavyface.svg#SortsMillGoudyItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Base Styles */
// NOTE: html is set to 62.5% so that all the REM measurements 10px sizing. So basically 1.5rem = 15px :)
html {
    font-size: 62.5%;
}

body {
    font-size: 1.4em; /* currently ems cause chrome bug misinterpreting rems on body element */
    font-weight: 400;
    font-family: $font-main;
    background-color: colors.$color-normal-parchment;
    color: colors.$color-grey;

    min-width: 300px; /* suppose you want minimun width of 1000px */
    width: auto !important;  /* Firefox will set width as auto */
    width: 300px;
}

html, body {
    overflow-x: hidden;
}


/* Typography */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    font-weight: 700;

    .subtitle {
        display: block;
        font-weight: normal;
    }

    .info-subtitle {
        display: block;
        font-weight: normal;
        font-style: italic;
    }
}

.fancy-header {
    font-family: $font-header;
    font-size: 3.2rem;
    font-weight: normal;
    margin-bottom: 2rem;

    @include respond-to($device-size-small)
    {
        font-size: 3.6rem;
        letter-spacing: 0.1rem;
    }

    @include respond-to($device-size-medium)
    {
        font-size: 4.8rem;
        letter-spacing: 0.1rem;
    }

    @include respond-to($device-size-large)
    {
        font-size: 6.0rem;
        letter-spacing: 0.1rem;
    }
}

.large-h1 {
    @extend .fancy-header;
    font-family: $font-main;
    font-weight: 700;
}

.h1 {
    font-size: 2.4rem;
    line-height: 1.25;
    margin-bottom: 2.0rem;

    @include respond-to($device-size-small)
    {
        font-size: 3.2rem;
    }
}

.h2 {
    font-size: 2.1rem;
    line-height: 1.25;
    margin-bottom: 1.5rem;

    @include respond-to($device-size-small)
    {
        font-size: 2.6rem;
    }
}

.h3 {
    font-size: 1.8rem;
    line-height: 1.25;
    margin-bottom: 1.25rem;

    @include respond-to($device-size-small)
    {
        font-size: 2.1rem;
    }
}

.h4, .h5, .h6 {
    font-size: 1.6rem;
    line-height: 1.25;
    margin-bottom: 1rem;

    @include respond-to($device-size-small)
    {
        font-size: 1.8rem;
    }
}

p, .info-detail {
    margin: 0 0 1.4rem 0;
    line-height: 1.5;
    color: colors.$color-grey;
    font-size: 1.4rem;
    letter-spacing: 0.02rem;

    @include respond-to($device-size-small)
    {
        font-size: 1.6rem;
    }

    .statement &, .text-container & {
        font-size: 1.6rem;

        @include respond-to($device-size-small)
        {
            font-size: 1.8rem;
        }
    }

    .quote & {
        font-size: 2.0rem;
        color: colors.$color-dark-green;

        @include respond-to($device-size-small)
        {
            font-size: 2.4rem;
        }
    }

    &.warning {
        color: red;
        font-weight: bold;
    }
}

ol, ul {
    line-height: 1.5;
    color: colors.$color-grey;
    font-size: 1.4rem;
    letter-spacing: 0.02rem;

    @include respond-to($device-size-small)
    {
        font-size: 1.6rem;
    }

    .text-container & {
        font-size: 1.6rem;

        @include respond-to($device-size-small)
        {
            font-size: 1.8rem;
        }
    }

    .statement & {
        font-size: 1.6rem;

        @include respond-to($device-size-small)
        {
            font-size: 1.8rem;
        }
    }
}

h1 { @extend .h1; }
h2 { @extend .h2; }
h3 { @extend .h3; }
h4, h5, h6 { @extend .h4; }


/* Links */
a {
    color: colors.$color-grey;
    text-decoration: none;
    letter-spacing: 0.02rem;

    p & {
        text-decoration: underline;
    }

    .statement > &, .category-header > &, .category-footer > & {
        font-size: 1.6rem;
        color: colors.$color-light-green;
        display: inline-block;

        @include respond-to($device-size-small)
        {
            font-size: 1.8rem;
        }
    }
}

input[type=checkbox] {
    @include appearance(none);
    outline: 0;
    border: none;
    width: 2.4rem;

    & + label, & + input[type=hidden] + label {
        vertical-align: top;
        line-height: 2.4rem;
        height: 2.4rem;
        margin: 0 0 0 2px;
    }

    &:before {
        display: block;
        color: colors.$color-grey;
        font: normal normal normal 24px/1 FontAwesome;
        font-size: 2.4rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f096";
    }

    &:checked {
        background: none;

        &:before {
            content: "\f046";
        }
    }
}

input[type=text], input[type=email], input[type=tel], input[type=search], input[type=password], select, textarea {
    border: 0.1rem solid colors.$color-light-grey;
    background-color: colors.$color-light-parchment;
    border-radius: .3rem;
    width: calc(100% - 1.8rem);
    font-size: 1.8rem;
    color: black;
    padding: 0.8rem;

    .background-image .form-panel & {
        border: none;
        background-color: colors.$color-white;
        width: calc(100% - 1.6rem);
    }
}

select {
    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @include appearance(none);

    width: 100%;

    background-image: linear-gradient(45deg, transparent 50%, colors.$color-light-green 50%), linear-gradient(135deg, colors.$color-light-green 50%, transparent 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 10px) 1em;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
}

input[type=submit], button[type=submit], button.submit {
    width: 100%;
    margin-top: $margin-medium;

    @include respond-to($device-size-small)
    {
        width: auto;
    }
}



.field-validation-error,
.field-validation-warning {
    display: block;
    border-radius: 3px;
    margin-top: $margin-small;
    padding: 0.8rem 1.2rem;
    position: relative;

    &:before {
        content: ' ';
        position: absolute;
        top: -0.8rem;
        left: 1.6rem;
        width: 0;
        height: 0;
        border-left: 0.8rem solid transparent;
        border-right: 0.8rem solid transparent;
        border-bottom: 0.8rem solid;
    }

    a {
        display: initial;
        text-decoration: underline;
    }
}

.field-validation-error {
    color: colors.$color-black;
    background: colors.$color-light-red;
    &:before {
        border-bottom-color: colors.$color-light-red;
    }
}

.field-validation-warning {
    color: colors.$color-black;
    background: colors.$color-light-yellow;
    &:before {
        border-bottom-color: colors.$color-light-yellow;
    }
}

%background-block {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100vw;
        height: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        background-color: colors.$color-light-green;
    }
}

.block-color {
    @extend %background-block;
    color: colors.$color-white;

    p, a {
        color: colors.$color-white;
    }
}

.background-image {
    @extend .block-color;

    &:before {
        background-color: colors.$color-black-green;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
    }
}

.promo-container {
    @extend .block-color;
}

.light-section {
    @extend %background-block;

    &:before {
        background-color: colors.$color-light-parchment;
    }
}

.container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 $margin-small;

    @include respond-to($device-size-medium)
    {
        width: 90%;
        padding: 0;
    }
}

.page-container {
    @extend .container;

    @include displayflex();
    min-height: 100vh;
    @include flex-direction(column);
    box-sizing: border-box;
    position: relative;
}

.notification-modal {
    display: none;

    p:last-child {
        margin-bottom: 0;
    }
}

.notification-modal-no-title {
    .ui-dialog-titlebar {
        display:none;
    }
}

.temporary-notification {
    position: fixed;
    //margin: 5% auto;
    width: 100%;
    top: 0;
    //left: 0;
    //right: 0;

    background-color: colors.$color-dark-green;
    //border: 2px solid colors.$color-light-orange;
    padding: $margin-small;
    //border-radius: $corner-rounding;
    z-index: 14;

    p {
        color: colors.$color-white;
        margin: 0;
        font-weight: bold;
        text-align: center;
    }
}


/* MOLECULES
-------------------------------------------------- */
.logo {
    font-family: $font-header;
    font-weight: normal;
    font-size: 2.1rem;
    @include transform(rotate(-4deg));
    display: inline-block;
    text-decoration: none;

    &:hover {
        text-decoration: none !important;
    }

    @include respond-to($device-size-medium)
    {
        font-size: 2.4rem;
    }

    @include respond-to($device-size-large)
    {
        font-size: 3rem;
    }
}

.other-logo {

}

%button {
    display: inline-block;
    box-shadow: colors.$color-light-green 0 2px;
    border: none;
    border-radius: $corner-rounding;
    padding: 1rem 1.5rem;
    background-color: colors.$color-normal-parchment;
    text-decoration: none;
    font-size: 1.6rem;
    //font-weight: 700;
    color: colors.$color-light-green !important;
    transition: background-color 0.2s;

    &:hover {
        background-color: darken(colors.$color-normal-parchment, 2%);
    }

    &:focus, &:active {
        outline: 0;
    }

    &.button-large {
        padding: 1.6rem 2.8rem;
    }
    
    .fa {
        padding-right: 0.8rem;
    }

    .button-text {
        float: left;
    }

    .button-price {
        float: right;
        font-size: 1.2em;
        line-height: 1em;
        font-weight: 700;
    }

    .button-subtext {
        display: block;
        text-align: center;
        font-size: 0.9em;
        margin-top: 0.2rem;
    }

    &.purchase {
        width: 100%;
    }

    &.button-mid-form {
        margin-bottom: 2rem;
        width: 100%;
        text-align: center;
    }

    .input-button-combo & {
        margin-left: $margin-small;
    }

    .button-couplet & {
        margin: 0;
        margin-left: $margin-small;
        font-size: 1.5rem;
        padding: 0.6rem 1.0rem;

        @include respond-to($device-size-tiny)
        {
            margin-left: $margin-medium;
            font-size: 1.6rem;
            padding: 1rem 1.5rem;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    @at-root .small-card-container .category-footer a#{&} {
        text-align: center;
        width: calc(100% - #{1.0rem * 2});
    }

    @include respond-to($device-size-tiny)
    {
        @at-root .small-card-container .category-footer a#{&} {
            width: calc(100% - #{1.5rem * 2});
        }
    }

    .small-card-container & {
        padding: 1rem;

        @include respond-to($device-size-tiny)
        {
            padding: 1rem 1.5rem;
        }

        .button-text {
            font-size: 1.4rem;

            @include respond-to($device-size-tiny)
            {
                font-size: initial;
            }
        }

        .button-price {
            font-size: 1.4rem;
            font-weight: normal;
            line-height: initial;

            @include respond-to($device-size-tiny)
            {
                font-size: initial;
            }
        }
    }

    .card h3 + & {
        margin-top: $margin-medium;
    }
}

.button-white {
    @extend %button;
    background-color: colors.$color-white;

    &:hover {
        background-color: darken(colors.$color-white, 2%);
    }
}

.button-light-parchment {
    @extend %button;

    border-style: solid;
    border-color: colors.$color-light-green;
    background-color: colors.$color-light-parchment;
    border-width: 1px 1px 0 1px;

    .background-image & {
        border: none;
    }

    &:hover {
        background-color: darken(colors.$color-light-parchment, 2%);
    }
}

.button-parchment {
    @extend %button;
    border-style: solid;
    border-color: colors.$color-light-green;
    border-width: 1px 1px 0 1px;

    .background-image & {
        border: none;
    }
}

.button-green {
    @extend %button;

    box-shadow: colors.$color-dark-green 0 2px;
    background-color: colors.$color-light-green;
    color: colors.$color-normal-parchment !important;

    &:hover {
        background-color: darken(colors.$color-light-green, 2%);
    }
}

.button-orange {
    @extend %button;
    box-shadow: colors.$color-dark-orange 0 2px;
    background-color: colors.$color-light-orange;
    color: colors.$color-white !important;

    &:hover {
        background-color: darken(colors.$color-light-orange, 2%);
    }
}

.button-red {
    @extend %button;
    box-shadow: colors.$color-dark-red 0 2px;
    background-color: colors.$color-normal-red;
    color: colors.$color-white !important;

    &:hover {
        background-color: darken(colors.$color-normal-red, 3%);
    }
}

.button-blue {
    @extend %button;
    box-shadow: colors.$color-dark-blue 0 2px;
    background-color: colors.$color-light-blue;
    color: colors.$color-white !important;

    &:hover {
        background-color: darken(colors.$color-light-blue, 3%);
    }
}

.button-grey {
    @extend %button;
    box-shadow: lighten(colors.$color-grey, 30%) 0 2px;
    background-color: colors.$color-light-grey;
    color: colors.$color-white !important;

    &:hover {
        background-color: colors.$color-light-grey;
    }
}

.button-couplet {
    @include displayflex();

}

.edit-box {
    width: 100%;
    margin-bottom: $margin-medium;

    label {
        font-weight: 700;
        font-size: 1.6rem;
        display: block;
        margin-bottom: calc($margin-small / 2);
    }

    input[type=checkbox] ~ label {
        display: inline-block;
        margin-left: 0.6rem;
    }

    a {
        text-align: right;
        display: block;
    }

    input[type=text], input[type=email], input[type=tel], input[type=search], input[type=password], select, textarea {
        & ~ a {
            margin-top: $margin-small;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    .field-validation-error {
        a {
            display: initial;
            text-decoration: underline;
        }
    }

    &.small-edit-box {
        @include respond-to($device-size-small)
        {
            width: calc(50% - #{calc($margin-medium / 2)});

            &+.small-edit-box {
                margin-left: $margin-medium;
            }
        }
    }

    &.vertical-radio-edit-box {
        @include displayflex();
        @include flex-flow(column nowrap);
        @include justify-content(center);
        margin: 0;

        .radio-button {
            text-align: left;
            margin-bottom: $margin-small;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.radio-edit-box {
        @include displayflex();
        @include flex-flow(row wrap);
        @include justify-content(center);

        > label {
            width: 100%;
        }

        .radio-button {
            width: calc(100% - #{$margin-small * 2} - 0.2rem);
            @include flex(0 0 calc(100% - #{$margin-small * 2} - 0.2rem));
            margin-bottom: $margin-small;

            @include respond-to($device-size-smallest)
            {
                width: calc(50% - #{$margin-small * 2.5} - 0.2rem);
                @include flex(0 0 calc(50% - #{$margin-small * 2.5} - 0.2rem));

                &:nth-of-type(2n+1) {
                    margin: calc($margin-small / 2) calc($margin-small / 2) calc($margin-small / 2) 0;
                }

                &:nth-of-type(2n) {
                    margin: calc($margin-small / 2) 0 calc($margin-small / 2) calc($margin-small / 2);
                }
            }

            @include respond-to($device-size-small)
            {
                width: calc(100% - #{$margin-small * 2} - 0.2rem);
                @include flex(0 0 calc(100% - #{$margin-small * 2} - 0.2rem));

                &:nth-of-type(2n+1) {
                    margin: 0 0 $margin-small;
                }

                &:nth-of-type(2n) {
                    margin: 0 0 $margin-small;
                }
            }

            @include respond-to($device-size-large)
            {
                width: calc(50% - #{$margin-small * 2.5} - 0.2rem);
                @include flex(0 0 calc(50% - #{$margin-small * 2.5} - 0.2rem));

                &:nth-of-type(2n+1) {
                    margin: calc($margin-small / 2) calc($margin-small / 2) calc($margin-small / 2) 0;
                }

                &:nth-of-type(2n) {
                    margin: calc($margin-small / 2) 0 calc($margin-small / 2) calc($margin-small / 2);
                }
            }

            .signup-container &, .shop-container & {
                width: calc(50% - #{$margin-small * 2.5} - 0.2rem);
                @include flex(0 0 calc(50% - #{$margin-small * 2.5} - 0.2rem));

                &:nth-of-type(2n+1) {
                    margin: calc($margin-small / 2) calc($margin-small / 2) calc($margin-small / 2) 0;
                }

                &:nth-of-type(2n) {
                    margin: calc($margin-small / 2) 0 calc($margin-small / 2) calc($margin-small / 2);
                }
            }
        }

        .choice-description {
            margin: $margin-small 0 0;
            text-align: center;
            width: 100%;
        }
    }
}

.radio-button {
    border: 1px solid colors.$color-light-grey;
    border-radius: 0.3rem;
    display: inline-block;
    text-align: center;
    color: colors.$color-light-green;

    input[type=radio] {
        position: absolute;
        visibility: hidden;

        & + label span {
            display: block;
            position: relative;
            border: 2px solid colors.$color-light-green;
            border-radius: 100%;
            height: 1.8rem;
            width: 1.8rem;
            margin: 0 auto $margin-small;

            &::before {
                display: block;
                position: absolute;
                top: 0.3rem;
                left: 0.3rem;
                content: '';
                border-radius: 100%;
                height: 1.2rem;
                width: 1.2rem;
                background-color: initial;
            }

            .sign-up-options & {
                height: 1.6rem;
                width: 1.6rem;

                &::before {
                    height: 1.0rem;
                    width: 1.0rem;
                }

                @include respond-to($device-size-tiny)
                {
                    height: 1.8rem;
                    width: 1.8rem;

                    &::before {
                        height: 1.2rem;
                        width: 1.2rem;
                    }
                }
            }
        }

        &:checked + label span {
            &::before {
                background-color: colors.$color-light-green;
            }
        }
    }

    label {
        padding: $margin-small;
        margin: 0;
        font-weight: normal;
        cursor: pointer;
    }

    &.selected-radio-button {
        border: 1px solid colors.$color-light-green;
    }

    &.inline {
        label {
            line-height: 2.2rem;

            .sign-up-options & {
                line-height: 1.8rem;

                @include respond-to($device-size-tiny)
                {
                    line-height: 2.2rem;
                }
            }
        }

        input[type=radio] {

            & + label span {
                display: inline-block;
                vertical-align: bottom;
                margin: 0 0.6rem 0 0;
            }
        }
    }

    .signup-fixed-navigation &.display-button, .signup-fixed-navigation & label {
        padding: 0.9rem;

        @include respond-to($device-size-medium)
        {
            padding: $margin-small;
        }
    }

    .sign-up-options & label {
        font-size: 1.4rem;
        padding: 0.7rem;

        @include respond-to($device-size-tiny)
        {
            font-size: initial;
            padding: 0.9rem;
        }

        @include respond-to($device-size-medium)
        {
            padding: $margin-small;
        }
    }
}

.counter-input {
    @include displayflex();
    @include flex-flow(row nowrap);
    @include justify-content(center);

    .counter-edit-box & {
        margin-top: $margin-small;
    }

    input[type=text] {
        border-radius: 0;
        border-width: 1px 0 0 0;
        border-color: colors.$color-light-green;
        text-align: center;
        box-shadow: colors.$color-dark-green 0 2px;
        min-width: 12px;
    }

    .counter-button {
        padding: 1rem 1.5rem;
        cursor: pointer;
        width: auto;
    }

    .counter-button:disabled{
        opacity: 0.5;
        cursor: initial;
    }

    .counter-button:first-child {
        border-radius: $corner-rounding 0 0 $corner-rounding;
    }

    .counter-button:last-child {
        border-radius: 0 $corner-rounding $corner-rounding 0;
    }

    &.counter-input-large {
        .counter-button {
            padding: 1.6rem 2.2rem;
        }
    }

    @include respond-to($device-size-small)
    {
        &.counter-input-large {
            .counter-button {
                padding: 1.2rem 1.8rem;
            }
        }
    }

    @include respond-to($device-size-medium)
    {
        &.counter-input-large {
            .counter-button {
                padding: 1.6rem 2.2rem;
            }
        }
    }
}

.filter-box {
    margin-bottom: $margin-medium;
    width: 100%;

    .input-button-combo {
        @include displayflex();

    }
}

table {
    color: colors.$color-grey;
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    font-size: 1.6rem;

    th, td {
        border-bottom: 1px solid colors.$color-light-grey;
        text-align: left;
        padding: 1.2rem 2.4rem 1.2rem 0.8rem;
    }

    tr {
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    th {
        color: colors.$color-dark-green;
    }

    td {
        &.important {
            font-weight: 700;
        }
    }

    .number {
        text-align: right;
        padding: 1.2rem 0.8rem;
    }

    a {
        text-decoration: underline;
    }

    .less-important {
        display: none;

        @include respond-to($device-size-small)
        {
            display: table-cell;
        }
    }
}

.statement {
    margin: 0 auto;
    padding: $margin-small;
    text-align: center;

    ol, ul {
        text-align: left;
        margin-left: $margin-small;

        @include respond-to($device-size-small) {
            margin-left: $margin-medium;
        }

        @include respond-to($device-size-medium) {
            margin-left: $margin-xlarge;
        }
    }

    &.long-statement {
        h1, h2, h3, h4 {
            color: colors.$color-dark-green;
        }

        .background-image & {
            h1, h2, h3, h4 {
                color: colors.$color-white;
            }
        }
    }

    .card-container & {
        width: 100%;
        margin-bottom: $margin-medium * 2;
    }

    .form-control & {
        margin: 0 auto $margin-medium * 2;
    }

    @include respond-to($device-size-small)
    {
        width: 80%;
        padding: 0;

        .card-container & {
            width: 100%;
            padding: 0 10%;
        }
    }

    @include respond-to($device-size-large)
    {
        width: 58%;

        .card-container & {
            width: 100%;
            padding: 0 20%;
        }
    }

    p {
        margin: 0 auto $margin-small;

        &:last-child {
            margin: 0 auto 0;
        }
    }

    .background-image & {
        text-shadow: rgba(0,0,0,0.2) 0px 3px 10px;
    }

    .form-control & {
        text-shadow: none;
    }
}

.double-quote {
    @include displayflex();
    @include flex-wrap(wrap);
}

.quote {
    @extend .statement;
    position: relative;
    padding-top: $margin-medium * 2;

    .fa-quote-left {
        color: colors.$color-dark-parchment;
        z-index: -1;
        position: absolute;
        left: 50%;
        top: 0;
        @include transform(translateX(-50%));
        font-size: 8rem;

        @include respond-to($device-size-small)
        {
            font-size: 10rem;
        }
    }

    .attribution {
        font-size: 1.6rem;
        color: colors.$color-light-green;
        margin-top: $margin-medium;

        @include respond-to($device-size-small)
        {
            font-size: 1.8rem;
        }
    }

    .double-quote & {
        &:first-child {
            margin-bottom: $margin-medium * 2;
        }

        @include respond-to($device-size-medium)
        {
            width: 40%;
            padding: $margin-medium * 2 5% 0;

            &:first-child {
                margin-bottom: 0;
            }
        }
    }
}

.map {
    width: 100%;
    min-height: 260px;
    margin-top: $margin-medium * 2;
}

.map-legend {
    margin-top: $margin-medium;
    @include displayflex();
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(center);

    .home-delivery-icon {
        display: inline-block;
        background-color: rgba(255, 0, 0, 0.35);
        border: solid 2px rgba(255, 0, 0, 0.8);
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: $margin-small;
    }

    &, & img, & .home-delivery-icon {
        vertical-align: middle;
    }

    img {
        margin-right: $margin-small;
    }

    .legend-part {
        padding: 0 $margin-small;
    }
}

.icon-bar {
    @include displayflex();
    @include flex-flow(wrap);
    @include justify-content(center);
    margin: $margin-medium * 2 2rem 0;

    @include respond-to($device-size-smallest)
    {
        margin: $margin-medium * 2 5rem 0;
    }

    @include respond-to($device-size-small)
    {
        max-width: 56rem;
        margin: $margin-medium * 2 auto 0;
    }

    @include respond-to($device-size-medium)
    {
        max-width: 72rem;
    }

    .icon-item {
        text-align: center;
        width: 10rem;
        margin: $margin-medium 2rem;

        @include respond-to($device-size-smallest)
        {
            font-size: 1.8rem;
            margin: $margin-medium 3rem;
        }

        @include respond-to($device-size-small)
        {
            margin: $margin-medium 2rem;
        }

        @include respond-to($device-size-medium)
        {
            margin: $margin-medium 4rem;
        }

        .fa, img, [class^="ooooby-icon-"], [class*=" ooooby-icon-"] {
            display: block;
            width: 6rem;
            height: 6rem;
            line-height: 6rem;
            vertical-align: middle;
            margin: 0 auto $margin-medium;
            border-radius: 3rem;
            font-size: 3rem;

            @include respond-to($device-size-smallest)
            {
                width: 8rem;
                height: 8rem;
                line-height: 8rem;
                border-radius: 4rem;
                font-size: 4rem;
            }

            @include respond-to($device-size-medium)
            {
                width: 10rem;
                height: 10rem;
                line-height: 10rem;
                border-radius: 5rem;
                font-size: 5rem;
            }
        }

        [class^="ooooby-icon-"], [class*=" ooooby-icon-"] {
            font-size: 4.2rem;

            @include respond-to($device-size-smallest)
            {
                font-size: 5.6rem;
            }

            @include respond-to($device-size-medium)
            {
                font-size: 7rem;
            }
        }

        img {
            width: 10rem;
            height: 10rem;
            line-height: 10rem;
            border-radius: 5rem;

            @include respond-to($device-size-medium)
            {
                width: 12rem;
                height: 12rem;
                border-radius: 6rem;
            }
        }

        .fa, [class^="ooooby-icon-"], [class*=" ooooby-icon-"] {
            background-color: colors.$color-light-parchment;
            color: colors.$color-light-green;

            .background-image &, .block-color & {
                background-color: rgba(248, 242, 227, 0.2);
                color: colors.$color-white;
            }

            .light-section & {
                background-color: lighten(colors.$color-light-parchment, 2%);
            }
        }

        p {
            display: block;
            font-size: 1.6rem;
            margin: 0;

            @include respond-to($device-size-small)
            {
                font-size: 1.8rem;
            }
        }
    }

    &.large-icon-bar, &.image-icon-bar {
        margin: $margin-medium * 2 2rem 0;

        @include respond-to($device-size-small)
        {
            max-width: none;
            margin: $margin-medium * 2 0 0;
        }

        @include respond-to($device-size-medium)
        {
            max-width: none;
        }

        .icon-item {
            margin: $margin-medium 2rem;
            width: 100%;

            @include respond-to($device-size-small)
            {
                width: 60%;
            }

            @include respond-to($device-size-medium)
            {
                width: calc(33.33333% - 4rem);
            }
        }
    }

    &.image-icon-bar {
        margin: $margin-medium * 2 auto 0;

        @include respond-to($device-size-small)
        {
            max-width: 60rem;
        }

        @include respond-to($device-size-medium)
        {
            max-width: 82rem;
        }

        h1, h2, h3, h4 {
            color: colors.$color-dark-green;
            margin: 0;
        }

        .icon-item {
            margin: $margin-medium 2rem;
            width: 100%;

            @include respond-to($device-size-small)
            {
                width: calc(50% - 4rem);
            }

            @include respond-to($device-size-medium)
            {
                width: calc(33.33333% - 4rem);
            }
        }
    }
}

.info-detail {
    margin-bottom: $margin-small;

    &:last-of-type {
        margin-bottom: 0;
    }

    label {
        display: block;
        font-weight: 700;
    }

    span {
        display: block;
    }
}

.media-container {
    .video {
        @include respond-to($device-size-small)
        {
            width: 80%;
            margin: 0 auto;
        }
    }
}


/* ORGANISMS
-------------------------------------------------- */

.button-bar {
    @include displayflex();
    @include flex-flow(wrap);
    @include justify-content(center);

    a {
        margin: 0 1rem 2rem;
    }

    p {
        width: 100%;
        text-align: center;

        a {
            margin: 0;
        }
    }

    .section & {
        margin-top: 5rem;
    }

    .card-footer ~ & {
        margin-top: $margin-small;
    }
}

.section {
    padding: $margin-xlarge 0;

    > h1, > h2, > h3, > h4, > h5, > h6 {
        color: colors.$color-dark-green;
        text-align: center;
        @include flex(1 0 100%);

        &:not(:first-child) {
            margin-top: $margin-xlarge;
        }
    }

    &.card-container {
        @include displayflex();
        @include flex-flow(row wrap);
        @include justify-content(center);
        padding: #{$margin-xlarge - calc($margin-medium / 2)} (calc($margin-small / 2));

        .card-footer {
            margin-top: $margin-medium * 2;
            text-align: center;
            width: 100%;

            a {
                text-decoration: underline;
            }
        }

        .category-header {
            width: 100%;

            h2 {
                display: inline-block;
                margin-right: $margin-small;
            }
        }

        .category-footer {
            width: 100%;
        }

        &.signup-container, &.shop-container {
            @include align-items(flex-start);
            @include justify-content(flex-start);
        }

        &.signup-container {
            padding: $margin-medium * 2 0;

            @include respond-to($device-size-small)
            {
                padding: $margin-xlarge 0;
            }
        }

        &.small-card-container {
            @include justify-content(flex-start);
            padding: 0 0 $margin-medium * 2;

            &.signup-container, &.shop-container {
                @include align-items(flex-start);
            }

            &.signup-container {
                padding: $margin-medium * 2 0;

                @include respond-to($device-size-small)
                {
                    padding: $margin-xlarge 0;
                }
            }

            &.first-card-container {
                padding-top: $margin-xlarge;
            }

            &.last-card-container {
                padding-bottom: $margin-xlarge;
            }
        }

        &.delivery-card-container {
            @include align-items(flex-start);
        }
    }

    &.signup-container, &.shop-container {
        padding: $margin-medium * 2 0;

        @include respond-to($device-size-small)
        {
            padding: $margin-xlarge 0;
        }
    }

    &.navigation-container {
        padding-top: 0;

        .navigation {
            margin-bottom: $margin-xlarge;
        }
    }

    &.small-content-container {
        @include flex(1);
    }

    &.promo-container {
        padding: $margin-medium 0;
    }

    &.form-container {
        padding: $margin-medium 0;

        @include respond-to($device-size-small)
        {
            padding: $margin-xlarge 0;
        }
    }

    &.table-container {
        margin: 0 auto;

        @include respond-to($device-size-large)
        {
            width: 80%;
        }

        .item-display-container ~ & {
            padding-top: 0;
            width: 100%;
        }

        h1, h2, h3 {
            color: colors.$color-dark-green;
        }
    }

    &.item-display-container {

        padding: $margin-medium * 2 0;

        @include respond-to($device-size-medium)
        {
            padding: $margin-xlarge 0;
        }

        .title-info {
            h1 {
                margin-bottom: $margin-small;
            }

            .supplier, .growing-method {
                font-size: 1.6rem;
                margin-bottom: $margin-medium;
                display: block;

                @include respond-to($device-size-small)
                {
                    font-size: 1.8rem;
                }
            }

            .growing-method {
                font-style: italic;
                margin-bottom: 0;
            }

            a.supplier {
                color: colors.$color-light-green;
            }
        }

        .info-wrapper {
            @include respond-to($device-size-small)
            {
                @include displayflex();
            }
        }

        .main-info {
            margin-bottom: $margin-medium;
            position: relative;

            @include respond-to($device-size-small)
            {
                margin-bottom: 0;
                margin-right: 4rem + $margin-small;
                width: calc(70% - #{4rem + $margin-small});
            }

            @include respond-to($device-size-medium)
            {
                margin-right: 5rem + $margin-medium;
                width: calc(75% - #{5rem + $margin-medium});
            }

            .price {
                position: absolute;
                right: 0;
                top: $margin-small;
                z-index: 10;
                background-color: colors.$color-light-green;
                color: colors.$color-white;
                width: 6rem;
                height: 6rem;
                line-height: 6rem;
                vertical-align: middle;
                text-align: center;
                border-radius: 100%;

                @include respond-to($device-size-smallest)
                {
                    width: 8rem;
                    height: 8rem;
                    line-height: 8rem;
                    font-size: 1.8rem;
                }

                @include respond-to($device-size-small)
                {
                    right: -4rem;
                    top: $margin-small * 2;
                }

                @include respond-to($device-size-medium)
                {
                    right: -5rem;
                    top: $margin-medium * 2;
                    width: 10rem;
                    height: 10rem;
                    line-height: 10rem;
                    font-size: 2.1rem;
                }

                .price-content {
                    display: inline-block;
                    vertical-align: middle;

                    .total {
                        display: block;
                        line-height: normal;
                    }

                    .unit {
                        display: block;
                        line-height: normal;
                        font-size: 0.75em;
                    }
                }
            }

            & > img {
                width: 100vw;
                @include transform(translateX(-$margin-small));
                margin-bottom: $margin-medium;

                @include respond-to($device-size-small)
                {
                    width: 100%;
                    @include transform(none);
                    margin-bottom: $margin-medium * 2;
                }
            }
        }

        .extra-info {
            width: 100vw;
            @include transform(translateX(-$margin-small));

            @include respond-to($device-size-small)
            {
                width: auto;
                @include transform(none);
                height: 100%;
                width: 30%;
            }

            @include respond-to($device-size-medium)
            {
                width: calc(25% - #{$margin-medium * -4});
            }
        }

        .purchase-info {
            padding: $margin-medium;
            background-color: colors.$color-light-parchment;

            @include respond-to($device-size-small)
            {
                border-radius: 5px;
            }

            @include respond-to($device-size-large)
            {
                padding: $margin-medium * 2;
            }
        }

        &.recipe-display-container {
            .main-info {
                @include respond-to($device-size-small)
                {
                    margin-right: $margin-medium;
                    width: calc(70% - #{$margin-medium});
                }
            }

            .purchase-info {
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

    }

    &.linked-container {
        padding-bottom: 0;
    }
}

.solo-logo {
    text-align: center;
    margin-bottom: $margin-xlarge;

    .logo {
        font-size: 3.6rem;

        @include respond-to($device-size-medium)
        {
            font-size: 4.2rem;
        }
    }
}

.card {
    background-color: colors.$color-light-parchment;
    margin: $margin-medium 0;
    border-radius: $corner-rounding;
    overflow: hidden;
    box-shadow: rgba(0,0,0,0.03) 0 2px 1px, rgba(0,0,0,0.02) 0 8px 12px;
    transition: box-shadow 0.2s;
    display: block;
    position: relative;
    width: 100%;

    &.image-card {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        @include displayflex();
        @include flex-direction(column);
        @include justify-content(center);
        padding: $margin-xlarge 0;

        h3, p {
            color: colors.$color-white;
            text-align: center;
            margin: 0 25%;
        }

        h3 + p {
            margin-top: $margin-medium;
        }
    }

    .image {

        &.clickable-image {
            cursor: pointer;
        }

        img {
            width: 100%;
        }
    }

    .content {
        padding: $margin-small $margin-small $margin-medium * 2;

        p {
            margin: 0;
        }
    }

    .selected-check {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        color: colors.$color-white;
        font-size: 1.8rem;
        padding: $margin-small;
        z-index: 1;

        &:after {
            content: "";
            background: colors.$color-light-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 6rem;
            width: 12rem;
            z-index: -1;
            @include transform(rotateZ(45deg) translateY(-4rem) translateX(2rem));
        }
    }

    .signup-container &, .shop-container & {
        .image  {
            display: block;
            position: relative;

            img {
                display: block;
                user-select: none;
            }
        }

        .content {
            padding: $margin-small $margin-small $margin-medium;
        }

        .sign-up-options-container {
            position: relative;
            .out-of-stock-label {
                z-index: 7; // so that it renders on top of the price badge
            }
        }

        .sub-content {
            padding: $margin-small $margin-small 0 $margin-small;
            width: calc(100% - #{$margin-small * 2});

            h3 {
                margin: 0;
            }
        }

        .sign-up-options {
            padding: $margin-small $margin-small 0 $margin-small;
            width: calc(100% - #{$margin-small * 2});
            background-color: colors.$color-light-parchment;
            position: absolute;
            bottom: 0;
            display: none;
            z-index: 8;
        }

        &.selected {
            box-shadow: none;

            .selected-check {
                display: block;
            }

            .sign-up-options, .sub-content {
                padding: $margin-small calc(#{$margin-small} - 2px) 0 calc(#{$margin-small} - 2px);
                border: 2px solid colors.$color-light-green;
                border-width: 0 2px 0 2px;
            }

            .content {
                padding: #{$margin-small} calc(#{$margin-small} - 2px) calc(#{$margin-medium} - 3px);
                border-radius: 0 0 $corner-rounding $corner-rounding;
                border: 2px solid colors.$color-light-green;
                border-top: none;
            }

            .image  {
                &:before {
                    display: block;
                    border-radius: $corner-rounding $corner-rounding 0 0;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    border: 2px solid colors.$color-light-green;
                    border-bottom: none;
                }
            }
        }
    }

    .price {
        position: absolute;
        bottom: $margin-small;
        right: $margin-small;
        font-size: 1.8rem;
        font-weight: 700;
        color: colors.$color-light-green;

        .unit {
            font-weight: normal;
        }
    }

    h3 {
        color: colors.$color-light-green;
    }

    &:hover {
        box-shadow: rgba(0,0,0,0.04) 0 2px 1px, rgba(0,0,0,0.05) 0 4px 4px;
    }

    .signup-container.small-card-container &, .shop-container.small-card-container & {
        h3 {
            font-size: 1.4rem;
            min-height: 1.4rem * 3.75;

            @include respond-to($device-size-tiny)
            {
                font-size: 1.6rem;
                min-height: 1.6rem * 3.75;
            }
        }

        .sign-up-options {
            h3 {
                min-height: initial;
            }
        }
    }

    .small-card-container & {
        @include flex(0 0 calc(50% - #{calc(($margin-small * 2) / 2)}));
        width: calc(50% - #{calc(($margin-small * 2) / 2)});

        .selected-check {
            padding: 0.8rem;

            &:after {
                @include transform(rotateZ(45deg) translateY(-5rem) translateX(2rem));
            }
        }

        &:nth-of-type(2n+1) {
            margin: $margin-small $margin-small $margin-small 0;
        }

        &:nth-of-type(2n) {
            margin: $margin-small 0 $margin-small $margin-small;
        }

        .price {
            position: absolute;
            left: calc($margin-small / 2);
            top: calc($margin-small / 2);
            z-index: 6;
            background-color: colors.$color-light-green;
            color: colors.$color-white;
            width: 6rem;
            height: 6rem;
            line-height: 6rem;
            font-size: 1.6rem;
            vertical-align: middle;
            text-align: center;
            border-radius: 100%;

            @include respond-to($device-size-smallest)
            {
                width: 8rem;
                height: 8rem;
                line-height: 8rem;
                font-size: 1.8rem;
            }

            @include respond-to($device-size-small)
            {
                width: 6rem;
                height: 6rem;
                line-height: 6rem;
                font-size: 1.6rem;
            }

            @include respond-to($device-size-medium)
            {
                left: $margin-small;
                top: $margin-small;
                width: 7rem;
                height: 7rem;
                line-height: 7rem;
                font-size: 1.7rem;
            }

            .price-content {
                display: inline-block;
                vertical-align: middle;

                .total {
                    display: block;
                    line-height: normal;
                }

                .unit {
                    display: block;
                    line-height: normal;
                    font-size: 0.75em;
                }
            }
        }

        h3 {
            font-size: 1.6rem;
        }

        button {
            margin: 0;
        }
    }

    @include respond-to($device-size-small)
    {
        @include flex(0 0 calc(50% - #{calc(($margin-medium * 2) / 2)}));
        width: calc(50% - #{calc(($margin-medium * 2) / 2)});

        &:nth-of-type(2n+1) {
            margin: $margin-medium $margin-medium $margin-medium 0;
        }

        &:nth-of-type(2n) {
            margin: $margin-medium 0 $margin-medium $margin-medium;
        }

        .small-card-container & {
            @include flex(0 0 calc(33.3% - #{calc(($margin-small * 4) / 3)}));
            width: calc(33.3% - #{calc(($margin-small * 4) / 3)});

            &:nth-of-type(3n+1) {
                margin: $margin-small $margin-small $margin-small 0;
            }

            &:nth-of-type(3n+2) {
                margin: $margin-small;
            }

            &:nth-of-type(3n) {
                margin: $margin-small 0 $margin-small $margin-small;
            }
        }
    }

    @include respond-to($device-size-medium)
    {
        .small-card-container & {
            @include flex(0 0 calc(25% - #{calc(($margin-small * 6) / 4)}));
            width: calc(25% - #{calc(($margin-small * 6) / 4)});

            &:nth-of-type(4n+1) {
                margin: $margin-small $margin-small $margin-small 0;
            }

            &:nth-of-type(4n+2), &:nth-of-type(4n+3) {
                margin: $margin-small;
            }

            &:nth-of-type(4n) {
                margin: $margin-small 0 $margin-small $margin-small;
            }
        }
    }

    @include respond-to($device-size-large)
    {
        @include flex(0 0 calc(33.3% - #{calc(($margin-medium * 4) / 3)}));
        width: calc(33.3% - #{calc(($margin-medium * 4) / 3)});

        &:nth-of-type(3n+1) {
            margin: $margin-medium $margin-medium $margin-medium 0;
        }

        &:nth-of-type(3n+2) {
            margin: $margin-medium;
        }

        &:nth-of-type(3n) {
            margin: $margin-medium 0 $margin-medium $margin-medium;
        }

        .small-card-container & {
            @include flex(0 0 calc(20% - #{calc(($margin-small * 8) / 5)}));
            width: calc(20% - #{calc(($margin-small * 8) / 5)});

            &:nth-of-type(5n+1) {
                margin: $margin-small $margin-small $margin-small 0;
            }

            &:nth-of-type(5n+2), &:nth-of-type(5n+3), &:nth-of-type(5n+4) {
                margin: $margin-small;
            }

            &:nth-of-type(5n) {
                margin: $margin-small 0 $margin-small $margin-small;
            }
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    .people-card-container & {

        &:nth-of-type(n) {
            margin: 0 0 $margin-small * 2;
        }

        .content {
            padding-bottom: $margin-small;
        }
    }

    .delivery-card-container & {
        .content {
            padding: calc($margin-small / 2);
            @include displayflex;
            @include flex-flow(wrap);

            .delivery-section {
                @include flex(0 0 calc(50% - #{$margin-small}));
                width: calc(50% - #{$margin-small});
                padding: calc($margin-small / 2);

                .label-code {
                    @extend .h3;
                    text-align: right;
                }

            }

            .delivery-button {
                @include flex(0 0 calc(50% - 3rem - #{$margin-small}));
                width: calc(50% - 3rem - #{$margin-small});
                margin: calc($margin-small / 2);
                cursor: pointer;
                user-select: none;

                .fa {
                    margin-right: 0.6rem;
                }
            }

            .done-delivery {
            }

            .wide-delivery-section {
                @include flex(0 0 calc(100%));
                width: calc(100%);
            }
        }
    }
}

.flowchart-container {

    h2 {
        margin-bottom: $margin-xlarge;
    }

    .flowchart-card {
        position: relative;

        @include respond-to($device-size-small)
        {
            @include displayflex();
            @include flex-wrap(wrap);
            @include justify-content(center);
            margin: 0 auto;
        }

        @include respond-to($device-size-medium)
        {
            width: 80%;
        }

        .flowchart-number {
            position: absolute;
            top: -2.5rem;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-color: colors.$color-light-green;
            height: 5rem;
            line-height: 5rem;
            width: 5rem;
            border-radius: 2.5rem;
            text-align: center;
            vertical-align: middle;
            font-size: 2rem;
            font-weight: bold;
            color: colors.$color-white;

            @include respond-to($device-size-small)
            {
                top: -4rem;
                height: 8rem;
                line-height: 8rem;
                width: 8rem;
                border-radius: 4rem;
                font-size: 3rem;
                @include order(1);
            }
        }

        .flowchart-info {
            background-color: colors.$color-light-parchment;
            padding: $margin-medium;

            @include respond-to($device-size-small)
            {
                min-height: 20rem;
                padding: $margin-medium * 2;
                width: calc(40% - #{$margin-medium * 4});
                @include flex-grow(2);
                @include order(2);
            }

            @include respond-to($device-size-medium)
            {
                padding: $margin-medium * 3;
                width: calc(40% - #{$margin-medium * 6});
            }

            .fa, [class^="ooooby-icon-"], [class*=" ooooby-icon-"] {
                font-size: 6rem;
                color: colors.$color-light-green;
                margin-bottom: $margin-small;

                @include respond-to($device-size-medium)
                {
                    margin-bottom: $margin-medium;
                }
            }

            [class^="ooooby-icon-"], [class*=" ooooby-icon-"] {
                font-size: 8rem;

                &:before {
                    display: block;
                }
            }

            p {
                margin: 0;
                font-size: 1.6rem;

                @include respond-to($device-size-small)
                {
                    font-size: 1.8rem;
                }
            }

            a.flowchart-link {
                display: block;
                margin-top: $margin-medium;

                &, & .fa {
                    font-size: 1.6rem;
                    color: colors.$color-light-green;
                    font-weight: 700;
                }

                .fa {
                    margin-right: 0.5rem;
                }
            }
        }

        .flowchart-image {
            background-color: colors.$color-black-green;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
            height: 12rem;

            @include respond-to($device-size-small)
            {
                height: auto;
                width: 60%;
                @include flex-grow(3);
                @include order(3);
            }

        }


        &:nth-of-type(2n) {
            .flowchart-info {
                @include respond-to($device-size-small)
                {
                    @include order(3);
                }
            }

            .flowchart-image {
                @include respond-to($device-size-small)
                {
                    @include order(2);
                }
            }
        }

        &:nth-of-type(1) {
            .flowchart-image {
                @include backgroundimage('background-produce-1');
            }
        }

        &:nth-of-type(2) {
            .flowchart-image {
                @include backgroundimage('background-basket-1');
            }
        }

        &:nth-of-type(3) {
            .flowchart-image {
                @include backgroundimage('background-farmer-3');
            }
        }

        &:nth-of-type(4) {
            .flowchart-image {
                @include backgroundimage('background-produce-2');

                .home-howitworks & {
                    @include backgroundimage('background-produce-6');
                }
            }
        }

        &:nth-of-type(5) {
            .flowchart-image {
                @include backgroundimage('background-produce-6');

                .home-howitworks & {
                    @include backgroundimage('background-produce-2');
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.separate-card {
            margin: $margin-medium * 3 auto;

            &:first-of-type {
                margin-bottom: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .connector {
        height: $margin-medium * 3;
        width: 50%;
        display: block;

        @include respond-to($device-size-small)
        {
            height: $margin-medium * 6;
            margin: 0 auto;
        }

        &:before {
            content: '';
            height: $margin-medium * 3;
            border-right: 2px dashed colors.$color-light-grey;
            display: block;

            @include respond-to($device-size-small)
            {
                border-bottom: 2px dashed colors.$color-light-grey;
                margin: 0 auto;
            }
        }

        &:after {
            @include respond-to($device-size-small)
            {
                content: '';
                height: $margin-medium * 3;
                border-left: 2px dashed colors.$color-light-grey;
                display: block;
            }
        }

        &:nth-of-type(2n+1) {
            &:before {
                @include respond-to($device-size-small)
                {
                    border-left: 2px dashed colors.$color-light-grey;
                    border-right: none;
                }
            }

            &:after {
                @include respond-to($device-size-small)
                {
                    border-left: none;
                    border-right: 2px dashed colors.$color-light-grey;
                }
            }
        }
    }
}

.text-container {
    margin: 0 auto;

    @include respond-to($device-size-small) {
        width: 84%;
    }

    @include respond-to($device-size-large) {
        width: 66%;
    }

    h1 {
        margin-bottom: $margin-medium;
    }

    h2, h3, h4, h5, h6 {
        text-align: left;
        color: colors.$color-dark-green;
        margin-top: $margin-medium * 1.5;

        &:first-child {
            margin-top: 0;
        }

        a & {
            color: colors.$color-dark-green;
        }
    }

    img {
        max-width: 100%;

        &.right-align {
            float: right;
            width: 50%;
            padding: 0 0 $margin-small $margin-small;
        }
    }

    p.image {
        text-align: center;
    }
}

.flow-text-container {

    @include respond-to($device-size-medium) {
        display: flex;
        flex-wrap: wrap;
    }

    .flow-text-item {
        @include respond-to($device-size-medium) {
            flex: 0 0 50%;
        }
    }
}

.form-control {
    background: colors.$color-light-parchment;
    color: colors.$color-grey;

    input[type=submit], button {
        width: 100%;
    }

    p {
        color: colors.$color-grey;
        margin: $margin-medium 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1, h2, h3 {
        color: colors.$color-grey;

        &+p {
            text-align: center; // What is this for? Center for MailChimp sign up forms. Was left.
        }
    }

    a {
        color: colors.$color-grey;
    }

    form {
        @include displayflex();
        @include flex-wrap(wrap);
    }
}

.form-panel {
    @extend .form-control;

    margin: 0 auto;
    border-radius: 0.6rem;
    padding: $margin-medium;

    .background-image & {
        background: rgba(255, 255, 255, 0.8);
    }

    p + & {
        margin-top: $margin-medium * 2;
    }

    .statement + & {
        margin-top: $margin-xlarge;

        .signup-container &, .shop-container & {
            margin: $margin-medium * 2 auto;
        }
    }

    @include respond-to($device-size-small)
    {
        width: calc(80% - #{$margin-xlarge * 2});
        padding: $margin-medium * 2 $margin-xlarge;

        &.large-form-panel {
            width: calc(90% - #{$margin-medium * 2});
            padding: $margin-medium * 2;
        }
    }

    @include respond-to($device-size-medium)
    {
        width: 40%;

        &.large-form-panel {
            width: 60%;
            padding: $margin-medium * 2 $margin-xlarge;
        }
    }

    & > .edit-box, & > .edit-group-couplet, & > .edit-group-triplet,
    & > form > .edit-box, & > form > .edit-group-couplet, & > form > .edit-group-triplet {
        &:last-child {
            margin-bottom: 0;
        }

        & ~ p {
            margin-top: 0;
            width: 100%;
            text-align: center;
        }
    }

    .button-couplet {
        input[type=submit], button {
            width: auto;
        }
    }

    .edit-group-couplet {
        width: 100%;

        @include respond-to($device-size-smallest)
        {
            @include displayflex();

            .edit-box {
                &:nth-of-type(2n+1) {
                    margin-right: calc($margin-small / 2);
                }

                &:nth-of-type(2n) {
                    margin-left: calc($margin-small / 2);
                }
            }
        }
    }

    .optional-input-group {
        width: 100%;
        margin-bottom: $margin-medium;

        input[type=text] {
            width: calc(100% - 7.4rem - 1.8rem - #{$margin-small});
            margin-right: $margin-small;
        }

        input[type=submit], button {
            width: 7rem; // 'Apply' button
        }
    }

    .map, .map-legend {
        width: 100%;
        margin: 0;
    }

    .map, .legend-part {
        margin: 0 0 $margin-medium;
    }
}

.payment-panel {
    form {
        @include justify-content(space-between);
    }

    a {
        text-decoration: underline;

        font-size: 1.4rem;

        @include respond-to($device-size-small)
        {
            font-size: 1.6rem;
        }
    }

    .button-couplet {
        a {
            text-decoration: none;
        }
    }

    .billing-period {
        margin-bottom: $margin-medium;

        @include respond-to($device-size-small)
        {
            width: 40%;
            max-width: 20rem;
        }
    }

    .promo-total {
        @include displayflex();
        @include justify-content(space-between);
        @include align-items(center);
        width: 100%;

        @include respond-to($device-size-small)
        {
            width: 60%;
            @include align-items(flex-end);
            @include justify-content(flex-end);
            margin-bottom: $margin-medium;
        }

        .voucher-input {
            input[type=text] {
                width: 8rem;
            }

            input[type=submit], button {
                width: auto;
            }
        }

        .total-price {
            font-weight: 700;
            font-size: 1.8rem;

            @include respond-to($device-size-small)
            {
                margin-left: $margin-medium;
            }
        }
    }

    .terms-conditions-check {
        width: 100%;
        margin-top: $margin-small;

        @include respond-to($device-size-small)
        {
            width: 60%;
            margin-bottom: 0;
        }

        label {
            font-weight: normal;
            font-size: 1.4rem;

            @include respond-to($device-size-small)
            {
                font-size: 1.6rem;
            }

            a {
                display: inline;
            }
        }
    }

    .button-couplet {
        width: 100%;
        @include justify-content(flex-end);
        @include align-self(flex-end);

        @include respond-to($device-size-small)
        {
            width: 40%;
        }

        input[type=submit] {
            max-width: 14rem;
        }
    }
}

.order-summary {
    width : 100%;
    margin-bottom: $margin-small;
    border-bottom: 2px solid colors.$color-light-grey;
    font-size: 1.6rem;

    @include respond-to($device-size-small)
    {
        font-size: 1.8rem;
    }

    .item-line.item-line-out-of-stock {
        .title > *:not(.out-of-stock-warning),
        .detail,
        .item-price {
            text-decoration: line-through;
        }
    }

    .item-line {
        @include displayflex();
        @include justify-content(space-between);
        margin-bottom: $margin-medium;

        .item-description {
            .out-of-stock-warning {
                color: colors.$color-dark-orange;
                display: block;
            }

            .title {
                font-weight: 700;
                margin-bottom: calc($margin-small / 4);

                .title-note {
                    font-weight: normal;
                    font-size: 0.9em;
                }
            }


            @include respond-to($device-size-medium)
            {
                @include displayflex();
                @include flex-wrap(nowrap);
                flex: 1;
                justify-content: space-between;
                width: 80%;

                .title {
                    width: 75%;
                }
            }

            @include respond-to($device-size-large)
            {
                .title {
                    width: 70%;
                }
            }
        }

        .item-price {
            font-weight: 700;
            text-align: right;
            margin-left: $margin-small;
        }
    }
}

.signup-fixed-navigation {
    position: fixed;
    z-index: 12;
    bottom: 0;
    left: 0;
    width: calc(100% - #{2 * $margin-medium});
    padding: $margin-medium;
    background-color: colors.$color-light-parchment;
    box-shadow: 0 -2px 12px 4px rgba(0,0,0,0.03);

    .container {
        @include displayflex();
        @include align-items(flex-end);
        padding: 0;
        width: 100%;
    }

    .order-details-box {
        display: none;

        @include respond-to($device-size-small)
        {
            display: inline-block;
        }
    }

    .edit-box {
        @include justify-content(flex-start);
        margin: 0;

        label {
            display: none;

            @include respond-to($device-size-smallest)
            {
                display: block;
            }
        }

        .radio-button {
            display: none;
            width: auto;
            max-width: 17rem;
            @include flex(0 0 auto);

            @include respond-to($device-size-small)
            {
                display: inline-block;
            }

            &.display-button {
                display: inline-block;

            }

            &:nth-of-type(3n+1) {
                margin: calc($margin-small / 2) calc($margin-small / 2) 0 0;
            }

            &:nth-of-type(3n+2) {
                margin: calc($margin-small / 2) calc($margin-small / 2) 0 calc($margin-small / 2);
            }

            &:nth-of-type(3n) {
                margin: calc($margin-small / 2) 0 0 calc($margin-small / 2);
            }
        }

        .drop-down {
            display: inline-block;
            width: 100%;
            max-width: 20rem;
            font-size: 1.6rem;
            color: colors.$color-light-green;

            @include respond-to($device-size-small)
            {
                display: none;
            }
        }
    }

    .button-form {
        margin-left: $margin-small;
        flex-shrink: 0;

        @include respond-to($device-size-small)
        {
            margin-bottom: 0.2rem;
            margin-left: 0;
        }

        @include respond-to($device-size-medium)
        {
            margin-bottom: 0.5rem;
        }

        form {
            @include displayflex();

            input[type=submit] {
                @include flex-grow(2);
                width: 100%;
                margin: 0 0 0 $margin-small;
                //min-width: 6.3rem;

                @include respond-to($device-size-medium)
                {
                    min-width: 10rem;
                }
            }

            button {
                @include flex-grow(1);
                min-width: 4.4rem;
                margin-top: 0;

                &.skip-button {
                    min-width: initial;
                }
            }
        }
    }

    &.signup-simple-fixed-navigation {
        .container {
            @include justify-content(flex-end);

            @include respond-to($device-size-small)
            {
                @include justify-content(space-between);
            }
        }

        .edit-box {
            display: none;

            @include respond-to($device-size-small)
            {
                display: block;
            }
        }

        .button-form {
            margin-left: 0;

            form {

                input[type=submit] {
                    padding: 1rem 3rem;
                }
            }
        }
    }

    .info-text {
        margin-top: 1rem;
        text-align: center;
    }
}

.signup-navigation {
    position: relative;
    box-sizing: border-box;
    color: colors.$color-light-parchment;
    text-align: center;

    .logo {
        color: colors.$color-light-parchment;
    }

    .logo-container {
        position: relative;
        padding: $margin-small 0;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            z-index: -1;
            width: 200vw;
            height: 100%;
            left: 50%;
            @include transform(translateX(-50%));
            background-color: colors.$color-light-green;
        }

        .other-logo {
            img.xsmall {
                display: inherit;
            }

            img.small {
                display: none;
            }

            img.medium {
                display: none;
            }
        }
    }

    .signup-steps {
        color: colors.$color-light-grey;
        position: relative;
        margin-top: $margin-medium;

        .step {
            display: inline-block;
            width: 5.5rem;

            .number {
                border: 0.2rem colors.$color-light-grey solid;
                border-radius: 100%;
                width: 2.8rem;
                height: 2.8rem;
                line-height: 2.8rem;
                display: block;
                vertical-align: middle;
                text-align: center;
                font-size: 1.6rem;
                font-weight: 700;
                margin: 0 auto;
            }

            .detail {
                font-size: 1.2rem;
            }

            &.complete {
                color: colors.$color-light-green;

                .number {
                    border-color: colors.$color-light-green;
                    color: colors.$color-light-green;
                }
            }
        }

        .step-connection {
            border-bottom: dashed 1px colors.$color-light-grey;
            display: inline-block;
            width: 26px;
            height: 16px;
            position: absolute;
            @include transform(translateX(-14px));

            &.complete {
                border-color: colors.$color-light-green;
            }
        }
    }

    @include respond-to($device-size-smallest) {
        .signup-steps {
            .step {
                margin-left: 2rem;

                &:first-child {
                    margin-left: 0;
                }
            }

            .step-connection {
                width: 46px;
            }
        }
    }

    @include respond-to($device-size-small) {
        @include displayflex();
        @include justify-content(space-between);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            z-index: -1;
            width: 200vw;
            height: 100%;
            left: 50%;
            @include transform(translateX(-50%));
            background-color: colors.$color-light-green;
        }

        .logo-container {
            position: unset;
            text-align: left;
            margin-left: $margin-medium;

            &:before {
                content: none;
            }

            .logo {
                font-size: 2.4rem;
            }

            .other-logo {
                img.xsmall {
                    display: none;
                }

                img.small {
                    display: inherit;
                }

                img.medium {
                    display: none;
                }
            }
        }

        .free-delivery {
            text-align: right;
            margin-right: $margin-medium;
        }

        .logo-container, .free-delivery {
            width: 20%;
            align-self: center;
        }

        .signup-steps {
            color: colors.$color-dark-green;
            margin: $margin-medium 0;

            .step {
                .number {
                    border-color: colors.$color-dark-green;
                }

                &.complete {
                    color: colors.$color-light-parchment;

                    .number {
                        border-color: colors.$color-light-parchment;
                        color: colors.$color-white;
                    }
                }
            }

            .step-connection {
                border-color: colors.$color-dark-green;

                &.complete {
                    border-color: colors.$color-light-parchment;
                }
            }
        }
    }

    @include respond-to($device-size-medium) {
        .logo-container {
            margin-left: 0;

            .logo {
                font-size: 2.8rem;
            }

            .other-logo {
                img.xsmall {
                    display: none;
                }

                img.small {
                    display: none;
                }

                img.medium {
                    display: inherit;
                }
            }
        }

        .signup-steps {

            .step {
                width: 5.5rem;
                margin-left: 5rem;

                &:first-child {
                    margin-left: 0;
                }

                .number {
                    width: 3.6rem;
                    height: 3.6rem;
                    line-height: 3.6rem;
                    font-size: 1.8rem;
                }

                .detail {
                    font-size: 1.6rem;
                }
            }

            .step-connection {
                width: 68px;
                height: 20px;
                @include transform(translateX(-10px));
            }
        }
    }
}

.navigation {
    padding: $margin-small 0;
    position: relative;
    box-sizing: border-box;
    height: ($margin-small * 2) + 3rem; // Set height so we can absolutely position menu
    &:before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -1;
        width: 200vw;
        height: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        background-color: colors.$color-light-green;
    }

    @include respond-to($device-size-medium) {
        height: auto;

        .background-image &:before {
            content: '';
            position: absolute;
            top: 0;
            z-index: -1;
            width: 200vw;
            height: 100%;
            left: 50%;
            @include transform(translateX(-50%));
            background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0));
        }
    }

    .fa-stack {
        width: 1.3em;
        height: 1.3em;
        line-height: 1.3em;
    }

    .shopping-basket {
        font-size: 1.3em;
    }

    .shopping-basket[data-count]:after {
        position: absolute;
        right: -18%;
        top: 1%;
        content: attr(data-count);
        font-size: 60%;
        padding: .3em;
        border-radius: 999px;
        line-height: .7em;
        background: rgba(255,0,0,.85);
        text-align: center;
        min-width: 1em;
        font-weight: bold;
    }

    .shopping-basket[data-count='0']:after {
        display: none;
    }

    .menu {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.6rem;
        //font-weight: bold;
        > li {
            color: colors.$color-white;

            a {
                color: colors.$color-light-parchment;

                &:hover, &:focus {
                    color: colors.$color-white;
                }

                &:focus {
                    text-decoration: underline;
                }
            }

            a:not(:first-child) {
                margin-left: $margin-medium;
            }

            .submenu-header {
                cursor: pointer;

                .fa {
                    padding-left: 0.4rem;
                    font-size: 0.8em;
                }

                &.hub-name {
                    font-weight: 700;
                }
            }
        }
    }

    .mobile-nav {
        margin: 0 $margin-medium;

        .logo-container {
            display: list-item;

            .other-logo {
                img {
                    display: block;
                }
            }
        }

        .shopping-basket {
            font-size: 1.0em;
            display: inline;
        }

        ul.menu {
            @include displayflex();
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            @include align-items(center);

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            .menu-name {
                display: none;
            }

            .fa {
                font-size: 1.3em;
                position: relative;
                top: 1px;
                margin-left: $margin-small;
                width: 2rem;
            }
        }

        @include respond-to($device-size-small) {
            ul.menu {
                .menu-name {
                    display: inline-block;
                }
            }
        }

        @include respond-to($device-size-medium) {
            display: none;
        }
    }

    .responsive-menu {
        display: none;
        position: absolute;
        top: ($margin-small * 2) + 3rem;
        right: -1 * $margin-small;
        height: auto;
        z-index: 10;
        width: calc(100vw - #{$margin-medium * 3});
        background-color: colors.$color-dark-green;
        padding: ($margin-medium - $margin-small) ($margin-medium * 1.5);

        &.visible-menu {
            display: block;
        }

        ul.menu {
            @include displayflex();
            @include flex-wrap(nowrap);
            @include flex-flow(column);
            @include justify-content(space-between);

            > li {
                position: relative;
                border-bottom: 1px solid darken(colors.$color-dark-green, 3%);

                a:not(.button-parchment), .submenu-header {
                    padding: $margin-small 0;
                    width: 100%;
                    display: block;
                }

                a.other-logo {
                    padding: 0;
                }
            }
        }

        ul.main-menu {
            &:first-child {
                > li:last-child {
                    border-bottom: 2px solid darken(colors.$color-dark-green, 3%);
                }
            }

            &:last-child {
                > li:last-child {
                    border-bottom: none;
                }
            }
        }

        ul.submenu {
            display: none;
            margin-left: $margin-medium;

            &.visible-menu {
                display: block;
            }

            > li:first-child {
                border-top: 1px solid darken(colors.$color-dark-green, 3%);
            }

            > li:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        li.optional-nav {
            display: none;

            .button-parchment {
                box-shadow: colors.$color-dark-green 0 2px;
            }
        }

        @include respond-to($device-size-small) {
            //width: 60vw;
        }

        @include respond-to($device-size-medium) {
            position: static;
            top: auto;
            left: auto;
            width: auto;
            height: auto;

            @include displayflex();
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            @include align-items(center);
            background-color: transparent;
            padding: 0;

            ul.menu {
                @include flex-flow(row);

                > li {
                    border: none !important;
                }
            }

            ul.main-menu {

                @include displayflex();
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                @include align-items(center);

                > li {
                    padding: 0 1.0rem;
                    position: relative;
                }

                &:first-child {
                    > li:first-child {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    @include flex-direction(row-reverse);

                    > li:first-child {
                        padding-right: 0;
                    }
                }
            }

            ul.submenu {
                display: none;
                position: absolute;
                top: 4rem;
                left: 0;
                z-index: 10;
                width: 15rem;
                background-color: colors.$color-dark-green;
                padding: calc($margin-medium / 2) $margin-medium;
                border-radius: $corner-rounding;
                font-weight: normal;

                > li, > li:first-child, > li:last-child {
                    border: none;
                }

                &.visible-menu {
                    display: block;
                }
            }

            li.optional-nav {
                display: list-item;
            }
        }

        @include respond-to($device-size-large) {
            ul.main-menu {
                > li {
                    padding: 0 1.5rem;
                }
            }
        }
    }
}

%base-footer {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -1;
        width: 200vw;
        height: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        background-color: colors.$color-black-green;
    }

    .footer-menu {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.4rem;

        @include displayflex();
        @include flex-wrap(wrap);

        > li {
            width: 100%;
            color: colors.$color-white;

            a {
                color: colors.$color-white;

                &:hover {
                    text-decoration: underline;
                }
            }

        }
    }
}

.simple-footer {
    @extend %base-footer;
    padding: $margin-medium * 2 0;

    @include respond-to($device-size-medium) {
        padding: $margin-medium 0;
    }

    .footer-menu {
        @include justify-content(center);
        @include align-content(center);

        @include respond-to($device-size-medium) {
            @include justify-content(space-between);
        }

        > li {
            @include align-self(center);
            text-align: center;

            @include respond-to($device-size-medium) {
                width: auto;
            }

            &.ooooby-name {
                @include displayflex();
                @include flex-wrap(wrap);
                @include justify-content(center);
                @include align-content(center);
                margin-bottom: $margin-medium;

                @include respond-to($device-size-medium) {
                    margin-bottom: 0;
                }

                a {
                    margin-bottom: $margin-small;

                    @include respond-to($device-size-medium) {
                        margin-bottom: 0;
                        margin-right: $margin-small;
                    }
                }

                a, span {
                    display: block;
                    width: 100%;
                    text-align: center;
                    @include align-self(center);

                    @include respond-to($device-size-medium) {
                        width: auto;
                    }
                }

                span:first-child {
                    padding-right: $margin-small;
                }
            }
        }
    }
}

.footer {
    @extend %base-footer;
    padding: $margin-medium * 3 $margin-medium;

    .footer-menu {

        > li {
            margin-bottom: $margin-medium;

            &.link-lists {
                margin-bottom: 0;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    @include displayflex();
                    @include flex-wrap(wrap);

                    > li {
                        display: block;
                        padding-bottom: $margin-medium * 2;
                        width: 100%;

                        a {
                            display: block;
                            margin-bottom: $margin-medium;
                            opacity: 0.8;

                            &:first-child {
                                font-weight: bold;
                                opacity: 1.0;
                            }
                        }

                        & .login-links {
                            display: block;

                            a {
                                display: inline-block;
                                font-weight: bold;
                                opacity: 1.0;

                                &:first-child {
                                    margin-right: $margin-small;
                                }

                                &:last-child {
                                    margin-left: $margin-small;
                                }
                            }
                        }
                    }
                }
            }

            &.ooooby-name {
                @include displayflex();
                @include flex-wrap(wrap);
                @include justify-content(center);
                @include align-content(center);
                margin-top: $margin-medium * 3;

                a, span {
                    display: block;
                    width: 100%;
                    text-align: center;
                    margin-bottom: $margin-medium;
                }
            }

            &.social, &.contact {
                a, select {
                    display: block;
                    margin-bottom: $margin-medium;

                    .fa {
                        font-size: 2em;
                        margin-right: $margin-small;
                        position: relative;
                        top: 4px;
                    }

                    span {
                        opacity: 0.8;
                    }
                }
            }
        }

        @include respond-to($device-size-smallest) {
            > li {
                &.link-lists {
                    ul {
                        > li {
                            width: 50%;
                        }
                    }
                }
            }
        }

        @include respond-to($device-size-small) {
            > li {
                &.social, &.contact {
                    a, select, .number {
                        display: inline-block;
                        margin-right: $margin-medium * 2;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                &.link-lists {
                    ul {
                        > li {
                            width: 33.33333%;
                        }
                    }
                }
            }
        }

        @include respond-to($device-size-medium) {
            > li {
                &.link-lists {
                    ul {
                        > li {
                            width: 25%;
                        }
                    }
                }
            }
        }

        @include respond-to($device-size-large) {
            > li {
                &.link-lists {
                    ul {
                        > li {
                            width: 16.66666%;
                        }
                    }
                }
            }
        }
    }
}

/* TEMPLATES
-------------------------------------------------- */

.background-image-bread-1 {
    &:before {
        @include darkenimage('background-bread-1');
    }
}

.background-image-cooking-1 {
    &:before {
        @include darkenimage('background-cooking-1');
    }
}

.background-image-cooking-2 {
    &:before {
        @include darkenimage('background-cooking-2');
    }
}

.background-image-cooking-3 {
    &:before {
        @include darkenimage('background-cooking-3');
    }
}

.background-image-cooking-4 {
    &:before {
        @include darkenimage('background-cooking-4');
    }
}

.background-image-cooking-5 {
    &:before {
        @include darkenimage('background-cooking-5');
    }
}

.background-image-farmer-1 {
    &:before {
        @include darkenimage('background-farmer-1');
    }
}

.background-image-farmer-2 {
    &:before {
        @include darkenimage('background-farmer-2');
    }
}

.background-image-farmer-3 {
    &:before {
        @include darkenimage('background-farmer-3');

        & {
            background-position-y: 15%;
        }
    }
}

.background-image-farmer-4 {
    &:before {
        @include darkenimage('background-farmer-4', 0.75);
    }
}

.background-image-produce-1 {
    &:before {
        @include darkenimage('background-produce-1');
    }
}

.background-image-produce-2 {
    &:before {
        @include darkenimage('background-produce-2');
    }
}

.background-image-produce-3 {
    &:before {
        @include darkenimage('background-produce-3');
    }
}

.background-image-produce-4 {
    &:before {
        @include darkenimage('background-produce-4');
    }
}

.background-image-produce-5 {
    &:before {
        @include darkenimage('background-produce-5');
    }
}

.background-image-produce-6 {
    &:before {
        @include darkenimage('background-produce-6');
    }
}

.background-image-produce-7 {
    &:before {
        @include darkenimage('background-produce-7', 0.55);
    }
}

.background-image-produce-9 {
    &:before {
        @include darkenimage('background-produce-9');
    }
}

.background-image-produce-10 {
    &:before {
        @include darkenimage('background-produce-10');
    }
}

.background-image-produce-11 {
    &:before {
        @include darkenimage('background-produce-11');
    }
}

.background-image-produce-12 {
    &:before {
        @include darkenimage('background-produce-12');
    }
}

.background-image-produce-13 {
    &:before {
        @include darkenimage('background-produce-13');
    }
}

.background-image-produce-14 {
    &:before {
        @include darkenimage('background-produce-14');
    }
}

.background-image-xmas-1 {
    &:before {
        @include darkenimage('background-xmas-1');
    }
}

.background-image-xmas-2 {
    &:before {
        @include darkenimage('background-xmas-2');
    }
}

.background-image-error-1 {
    &:before {
        @include darkenimage('background-error-1');
    }
}

.background-image-work-1 {
    &:before {
        @include darkenimage('background-work-1', 0.70);
    }
}

.image-card-bread-2 {
    @include darkenimage('background-bread-2');
}

.image-card-produce-8 {
    @include darkenimage('background-produce-8', 0.65);
}


/* PAGES
-------------------------------------------------- */

.home-internationalhome {
    h1 {
        @extend .large-h1;
    }
}

.home-nationalhome {
    h1 {
        @extend .large-h1;
    }
}

.home-hubhome {
    h1 {
        @extend .fancy-header;
    }
}

.home-products {
    .card-container {
        @include justify-content(flex-start);
    }
}

.home-boxcontents {
    .box {
        margin-top: $margin-xlarge;
        @include displayflex();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        @include align-items(baseline);

        h1, h2, h3, h4, a {
            @include flex(0 1 auto);
        }

        .button-price {
            margin-left: $margin-small;
        }

        .box-list {
            @include flex(1 0 100%);
        }

        td, tr {

            &:nth-child(1) {
                width: 30%;
            }

            &:nth-child(2) {
                width: 25%;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(4) {
                width: 25%;
            }

        }
    }
}

.home-box {
    .box {
        margin-bottom: $margin-medium * 2;
    }
}

.home-termsandconditions, .home-privacypolicy {
    h2 {
        @extend .h3;
    }
}

/* jQuery UI Overrides
-------------------------------------------------- */

.ui-dialog .ui-dialog-content {
    padding: 2rem 2rem 2.5rem;
}

.ui-dialog .ui-dialog-title {
    font-size: 1.8rem;
}

.ui-dialog .ui-dialog-buttonpane {
    padding: .3em 1.4em .5em;

    .ui-dialog-buttonset {
        float: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        button:last-child {
            margin-right: 0;
        }
    }
}

.ui-button {
    &:focus, &:active {
        outline: 0;
    }
}

.ui-widget {
    overflow: visible;
}


/* Find Local Food Postcode lookup
-------------------------------------------------- */

.green-section {
    @extend %background-block;

    &:before {
        background-color: colors.$color-dark-green;

    }
}

.grey-section {
    @extend %background-block;

    &:before {
        background-color: colors.$color-public-background-grey;

    }
}

.white-section {
    @extend %background-block;

    &:before {
        background-color: colors.$color-white;
    }

    .quote {
        .fa-quote-left {
            color: colors.$color-public-background-grey;
        }

        p {
            color: colors.$color-grey
        }

        .attribution {
            color: colors.$color-light-grey
        }
    }
}

.postcode-lookup-controls {
    text-align: center;
    padding-top: $margin-medium;

    .logo {
        transform: none;
        color: colors.$color-white;
    }

    h1 {
        font-family: $font-header;
        color: colors.$color-white;
    }

    .location-input {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        max-width: $device-size-small;
        margin-left: auto;
        margin-right: auto;

        input {
            background-color: colors.$color-white;
            flex: 1 1 0;
        }

        .button-green {
            margin-top: 0;
        }
    }
}


.postcode-lookup-results {
    max-width: $device-size-small;
    margin: auto;

    .nearest-hub-item {
        margin-bottom: 2rem;
        padding: 2rem;
        border-radius: $corner-rounding;
        background-color: colors.$color-white;
        box-shadow: rgba(0, 0, 0, 0.03) 0 2px 1px, rgba(0, 0, 0, 0.02) 0 8px 12px;
        transition: box-shadow 0.2s;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.04) 0 2px 1px, rgba(0, 0, 0, 0.05) 0 4px 4px;
        }

        .hub-link {
            display: block;
        }
    }

    .hub-name {
        margin-bottom: 0.2em;
    }

    .delivery-icons {
        margin-top: $margin-small;

        .delivery-icon {
            display: flex;
            align-items: center;
        }

        .nearest-collection-point {
            font-size: smaller;
            margin-bottom: $margin-small;
        }
    }

    .ooooby-icon-van, .ooooby-icon-box-closed {
        font-size: xx-large;
        margin-right: 0.2em;
    }
}

.out-of-stock-label {
    position: absolute;
    color: white;
    background-color: rgba(0,0,0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 1.2em;
    justify-content: center;
    align-items: center;
}

/* Cookie control
-------------------------------------------------- */

.cookie-settings {
    position: fixed;
    bottom: 0;
    background: colors.$color-dark-green;
    font-size: 14px;
    font-family: $font-main;
    color: colors.$color-normal-parchment;
    border: none;
    left: 0;
    padding: calc($margin-small / 2) $margin-small;
    border-radius: 0 0 $corner-rounding $corner-rounding;
    z-index: 100;
    transform: rotate(270deg);
    transform-origin: 0 0;
}

/** Cookie control light color-scheme **/
:root{
    --cc-font-family: #{$font-main};
    --cc-bg: #{colors.$color-white};
    --cc-text: #{colors.$color-grey};
    --cc-btn-primary-bg: #{colors.$color-light-green};
    --cc-btn-primary-bg-shadow: #{colors.$color-dark-green};
    --cc-btn-primary-bg-border: #{colors.$color-light-green};
    --cc-btn-primary-text: #{colors.$color-normal-parchment};
    --cc-btn-primary-hover-bg: #{darken(colors.$color-light-green, 2%)};
    --cc-btn-primary-hover-text: var(--cc-btn-primary-text);
    --cc-btn-secondary-bg: #{colors.$color-white};
    --cc-btn-secondary-bg-shadow: #{colors.$color-dark-green};
    --cc-btn-secondary-bg-border: #{colors.$color-light-green};
    --cc-btn-secondary-text: #{colors.$color-light-green};
    --cc-btn-secondary-hover-bg: #{darken(colors.$color-white, 2%)};
    --cc-btn-secondary-hover-text: var(--cc-btn-secondary-text);
    --cc-toggle-bg-off: #919ea6;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #d5dee2;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-bg-hover: #e9eff4;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e9edf2;
    --cc-webkit-scrollbar-bg: #cfd5db;
    --cc-webkit-scrollbar-bg-hover: #9199a0;
}